<template>
  <div class="home container">
    <div class="content has-text-centered">
      <h1>CRC AI <strong>Brain</strong> Team is a community of AI Experts.</h1>
      <h4>Chat with AI Experts in these domains:</h4>
    </div>
    <CategoriesMenu/>

    <section class="section" v-if="!user.isLogged">
      <div class="content has-text-centered">
        <h4>For the best experience please login or create an Account</h4>
      </div>
      <div class="sx-adv-wrapper columns mb-3">
        <div class="column form-container">
          <LoginForm />
        </div>
        <div class="column form-container">
          <RegisterForm />
        </div>
      </div>
    </section>
    <section class="section" v-else>
      <div class="box">
          <div class="mb-3 has-text-centered is-size-4">
            Hey! You are logged in as <span class="username">{{ user.name }}</span>
          </div>
          <div class="mb-3 has-text-centered">
            <b-button
              class="mr-2"
              type="is-info"
              @click="goTo('Profile')"
              icon-left="user"
              :size="isMobile ? '' : 'is-medium'"
              >View Profile
            </b-button>
            <b-button
              type="is-success"
              @click="goTo('Chat')"
              icon-left="comments"
              :size="isMobile ? '' : 'is-medium'"
              >Start Chating
            </b-button>
          </div>

        </div>
    </section>
  </div>
</template>

<script>

import { mapState } from "vuex";

// @ is an alias to /src
import CategoriesMenu from "@/components/navigation/CategoriesMenu.vue";
import LoginForm from "@/components/forms/LoginForm.vue";
import RegisterForm from "@/components/forms/RegisterForm.vue";

export default {
  name: "HomeView",
  components: {
    CategoriesMenu,
    LoginForm,
    RegisterForm,
  },
  computed: {
    ...mapState(["isMobile", "lang"]),
    ...mapState({
      user: (state) => state.users.user,
      //isEditingID: (state) => state.adverts.advert.isEditingID,
    }),
  },
  beforeMount() {
    //this.$store.dispatch("adverts/getAdverts");
  },
  methods:{
    goTo(route) {
      this.$router.push({ name: route });
    },
  },
  metaInfo() {
      return {
        title: "AI Brain Team - Your personal AI assistants",
        htmlAttrs: {
          lang: "en"
        },
        meta: [
          {
            name: "description",
            content: "AI Brain Team - an interactive chats with diverse AI agents. Broadening your knowledge across various domains in an innovative and engaging manner."
          },
          {
            property: "og:title",
            content: "AI Brain Team - Your personal AI assistants",
            vmid: "og:title"
          },
          {
            property: "og:description",
            content: "AI Brain Team - an interactive chats with diverse AI agents. Broadening your knowledge across various domains in an innovative and engaging manner.",
            vmid: "og:description"
          },
          {
            property: "og:image",
            content: "https://ai.crctransport.us/img/share.png",
            vmid: "og:image"
          },
          {
            property: "og:url",
            content: "https://aibrainteam.com",
            vmid: "og:url"
          }
        ]
      };
  } //end meta
};
</script>

<style scoped>
.home{
  min-height: 100vh;
}
h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color:#4a4f54;
}

h4 {
  font-size: 1.2rem;
  font-weight: 400;
  color:#606468;
}

.username{
  text-transform: capitalize;
  font-weight: 700;
  color:#4a4f54;
}
</style>