import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import users from "./modules/users";
import modals from "./modules/modals";
import toast from "./modules/toast";

Vue.use(Vuex);

let apiUrl = process.env.VUE_APP_API_URL;

export default new Vuex.Store({
  state: {
    teams: [],
    currentTeam: null,
    agents: [],
    lang: "en",
    isMobile: false,
    selectedContact: null,
    messageLimit: 100,
  },
  getters: {
    // getTeamContacts: (state) => (teamID) => {
    //   let team = null;
    //   team = state.teams.find(team => team.id == teamID);
    //   console.log(team);
    //   return team.agents;
    // }
  },
  mutations: {
    setLang(state, lang) {
      console.log("setLanguage:", lang);
      state.lang = lang;
      localStorage.setItem("sxLang", lang);
    },
    setMobile(state, payload) {
      console.log("setMobile:", payload);
      state.isMobile = payload;
    },
    setTeams(state, payload) {
      console.log("setTeams:", payload);
      state.teams = payload;
    },
    setCurrentTeam(state, payload) {
      console.log("setCurrentTeam:", payload);
      state.currentTeam = payload;
      state.agents = [];
      let team = state.teams.find(team => team.id == payload);
      state.agents = team.agents;
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
    setCurrentContact(state, payload) {
      state.selectedContact = payload;
    },
    setMessageLimit(state, payload) {
      console.log("setMessageLimit:", payload);
      state.messageLimit = payload;
      localStorage.setItem("aiLim", payload);
    },
  },
  actions: {
    getTeams({ commit }) {
      axios.post(`${apiUrl}/teams/get_teams.php`, {
        action: 'getTeams',
      }).then(response => {
       console.log(response.data);
        if(response.data.success){
         commit("setTeams", response.data.teams);
        }else{
          console.log("no id");
        }
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
      });
    },
    
  },
  modules: {
    users,
    modals,
    toast,
  },
});

//!!!Classic Experts===================================
  //Lead Generation Specialist
  //React Developer
  // Sales Coach
  // Product Manager
  // Marketing Manager
  // Accountant
  // Search Engine Optimization Specialist
  // Business Coach
  // Lead Generation Specialist

//!!!Sales Experts===================================
    // Pipeline Development Expert
    // Sales Management Expert
    // Sales & Marketing Integration Expert
    // Network Development Expert
    // High Ticket Sales Expert
    // Sales Prospecting Expert
    // Sales Communication Expert

//!!!Marketing Experts===================================
    // Social Media Expert
    // Copywriter
    // Marketing Research Expert
    // Brand Management Expert
    // Event Marketing Expert
    // Digital Marketing Expert
    // Content Marketing Expert
    // Marketing Professor
    // Retail Marketing Expert

//!!!Finance Experts===================================
    // Financial Modeling Expert
    // Financial Accounting Expert
    // International Finance Expert
    // Entrepreneurial Finance Expert
    // Corporate Finance Expert
    // Financial Risk Expert
    // Behavioral Finance Expert
    // Bookkeeping Expert

//!!!Operations Experts===============================
    // Quality Management Expert
    // Lean Operations Expert
    // Production Planning & Control Expert
    // Inventory Management Expert
    // Project Management Expert
    // Business Intelligence Expert
    // Supply Chain Management Expert
    // Enterprise Resource Planning Expert


//!!!Human Resources Experts===============================

    // Human Resources Expert
    //HR Analytics & Metrics Expert
    //Diversity and Inclusion Expert
    //Employee Retention Expert
    //Employee Recruitment Expert
    //Employee Training Expert

//!!!Technology Experts===============================
    // Blockchain Expert
    // Web Development Expert
    // Mobile Development Expert
    // Machine Learning and Artificial Intelligence Expert
    // Data Science & Analytics Expert
    // Cloud Computing Expert
    // Information Architecture Expert

//Design Experts
    // Service Design Expert
    // Content Design Expert
    // Interaction Design Expert
    // Design Thinking Expert
    // Visual Design Expert
    // User Experience Design Expert
    // Branding Expert

//???
//!!!Legal Experts===============================

    // Legal Expert
    // Corporate Law Expert
    // Intellectual Property Expert
    // International Law Expert
    // Legal Research Expert
    // Legal Writing Expert
    // Legal Compliance Expert
    // Legal Translation Expert

//!!!Strategy Experts===============================
    // Business Strategy Expert
    // Strategy Expert
    // Strategic Planning Expert
    // Strategic Thinking Expert
    // Strategic Communications Expert
    // Strategic Partnerships Expert
    // Strategic Negotiations Expert
    // Strategic Leadership Expert



    // Other fields 

    //Medical Experts
    // Medical Expert
    // Physician Expert
    //General Medicine Expert
    //Pediatrician Expert
    //Dentist Expert
    //Psychiatrist Expert
    //Neurologist Expert
    //Cardiologist Expert
    //Dermatologist Expert
    //Gynecologist Expert
    //Ophthalmologist Expert
    //Orthopedist Expert
    //Otolaryngologist Expert
    //Urologist Expert
    //Gastroenterologist Expert
    //Endocrinologist Expert
    //Nephrologist Expert
    //Pulmonologist Expert
    //Oncologist Expert
    //Radiologist Expert
    //Anesthesiologist Expert
    //Surgeon Expert
    //General Practitioner Expert
    //Nurse Expert
    //Pharmacist Expert
    //Medical Assistant Expert
