<template>
  <b-navbar
    class="shadow"
    :mobile-burger="true"
    :fixed-top="false"
    type="is-white"
    :wrapper-class="['Chat'].indexOf($route.name) == -1? 'container':''"
    :active.sync = "isMobMenu"
  >
    <!-- <template #start>
              <b-navbar-dropdown :label="getLang(currentLang)">
                <b-navbar-item @click="langSwitch('ro')">
                    Rom
                </b-navbar-item>
                <b-navbar-item @click="langSwitch('ru')">
                    Рус
                </b-navbar-item>
                <b-navbar-item @click="langSwitch('en')">
                    Eng
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item tag="div" v-if="didScroll">
              <b-button type="is-light" @click="isMenu = !isMenu">Categories</b-button>
            </b-navbar-item>
          <b-navbar-item tag="div" v-if="didScroll">
            <b-field>
              <b-input placeholder="Search..."
                  type="search"
                  icon="magnifying-glass" expanded>
              </b-input>
              <p class="control">
                  <b-button type="is-primary" label="Search" />
              </p>
            </b-field>
          </b-navbar-item>
    </template> -->
    <template #brand>
      <b-navbar-item v-if="['Chat'].indexOf($route.name) == -1" tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/ai_logo.svg" width="150px" />
      </b-navbar-item>
      <a class="navbar-item" v-if="isMobile && ['Chat'].indexOf($route.name) !== -1"  @click="openContacts">
        <b-icon icon="angle-left" class="mr-1"></b-icon>
      </a>

      <b-navbar-item v-if="isMobile && ['Chat'].indexOf($route.name) !== -1 && selectedContact" @click="openContacts">
        <div class="contact-name">{{ selectedContact.label }} | {{selectedContact.speciality}} </div>
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Home
      </b-navbar-item>
      <b-navbar-dropdown label='About'>
          <b-navbar-item tag="router-link" :to="{ path: '/about' }">
            About this project
          </b-navbar-item>
          <b-navbar-item @click="openContactForm">
            Contact Us
          </b-navbar-item>

      </b-navbar-dropdown>
      <b-navbar-item v-if="isMobile && ['Chat'].indexOf($route.name) !== -1" @click="openContacts">
        aiContacts
      </b-navbar-item>
      <b-navbar-item v-if="!user.isLogged" @click="openModal('login')">
        Login
      </b-navbar-item>
      <b-navbar-dropdown label="Account" v-if="user.isLogged">
          <b-navbar-item tag="router-link" :to="{ path: '/profile' }">
            <b-icon icon="syntho user" class="mr-1"></b-icon> My Profile
          </b-navbar-item>
          <b-navbar-item @click="logout()">
            <b-icon icon="syntho arrow-right-from-bracket" class="mr-1"></b-icon> Logout
          </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<style scoped>
.shadow {
  width: 100%;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.17);
}
</style>

<script>
import { mapState } from "vuex";
import modalsMixin from "@/mixins/modalsMixin";

export default {
  name: "Navbar",
  mixins: [modalsMixin],
  data() {
    return {
      // currentLang: "Rom",
      didScroll: false,
      isMobMenu: false,
    };
  },
  mounted(){
    window.addEventListener('scroll', this.scrollPage);
  },
  computed: {
    ...mapState("users", ["user"]),
    ...mapState(["lang","isMobile","selectedContact"]),
    isMenu:{
      get(){
        return this.$store.state.showMenu;
      },
      set(val){
        this.$store.commit("setMenu", val);
      }
    },
   //get & set lang
   //works =================================
    // currentLang: {
    //   get() {
    //     return this.$store.state.lang;
    //   },
    //   set(lang) {
    //     this.$store.commit("setLang", lang);
    //   },
    // },

  },
  methods: {
    openContacts() {
      this.$store.commit("modals/showModal", "sidebarChat");
    },
    openContactForm() {
      this.$store.commit("modals/showModal", "contact");
    },
    logout() {
      this.$store.commit("users/logoutUser");
      this.$router.push({ path: "/" }).catch((err) => {
        console.log(err);
      });
    },
    //works =================================
    // langSwitch(lang) {
    //   console.log(lang);
    //   this.currentLang = lang;
    //   this.$i18n.locale = lang;
    // },
    // getLang(lang) {
    //   let langNames = {
    //     ro: "Rom",
    //     ru: "Рус",
    //     en: "Eng",
    //   };
    //   return langNames[lang] || "Eng";
    // },
  },
  watch:{
    isMobMenu(val){
      console.log("hide");
      if(val){
         if(this.$route.name == "Chat"){
          this.$store.commit("modals/hideModal", "sidebarChat");
        }
        //document.body.style.overflow = "hidden";
      }else{
        console.log("show");
        //document.body.style.overflow = "auto";
      }
    }
  }

};
</script>
<style scoped>
  .contact-name{
    white-space: nowrap;
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

</style>
