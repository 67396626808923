import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueMeta from "vue-meta";
//import {i18n} from "./plugins/i18n";
//import {marked} from "marked";
// import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
// Vue.use(LottieVuePlayer);

// import vClickOutside from 'v-click-outside';
// Vue.use(vClickOutside);


Vue.use(Buefy, {
  defaultIconPack: "syntho",
});
Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});


Vue.config.productionTip = false;

axios.defaults.baseURL = "https://ai.crctransport.us/api";

Vue.prototype.$env = {
  siteTitle: "CRC aiBrainTeam",
  siteUrl: "https://ai.crctransport.us/",
  shareImg: "/img/share.jpg",
  api: "https://ai.crctransport.us/api",
  imagesURL: "",
};


//{mangle: false}

// Vue.use({
//   install () {
//     Vue.marked = marked
//     Vue.prototype.$marked = marked
//   }
// });


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
