<template>
        <div class="from-wrapper">
          <div class="sx-block-title">Create a new account</div>
          <div class="form-body">
            <b-field horizontal v-if="form.visible" label="Name" :type="form.name.status" :message="form.name.message">
              <b-input type="text" v-model="form.name.data" placeholder="Your Name" required></b-input>
            </b-field>
            <b-field horizontal v-if="form.visible" label="Email" :type="form.email.status" :message="form.email.message">
              <b-input type="email" v-model="form.email.data" placeholder="Your email" required></b-input>
            </b-field>
            <!-- <b-field horizontal class="m-t-md" v-if="form.visible" :label="this.form.human.label" :type="form.human.status"
              :message="form.human.message">
              <b-input type="text" v-model="form.human.data" placeholder="Your answer" required></b-input>
            </b-field> -->

            <b-message :type="form.status" v-if="form.result">
              {{ form.message }}
            </b-message>
            <div v-if="!form.visible" class="has-text-centered">
              <button class="button is-primary" type="button" @click="openLogin()">
                Sign in Now
              </button>
            </div>

        </div>
        <div class="form-footer" v-if="form.visible">
          <button class="button is-primary" @click="onSubmit()">
            Register
          </button>
        </div>
      </div>


</template>

<script>
import formCheck from "@/mixins/formCheck";
export default {
  name: "RegisterForm",
  mixins: [formCheck],
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        name: {
          data: "",
          status: "",
          message: ""
        },
        email: {
          data: "",
          status: "",
          message: ""
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    capitalizeWords(string) {
      let arr = string.split(" ");
      return arr.map(element => {
        return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
      }).join(" ");
    },
    generateCapcha() {
      let var0 = Math.floor(Math.random() * 9) + 1;
      let var1 = Math.floor(Math.random() * 9) + 1;
      this.form.human.summ = var0 + var1;
      this.form.human.label = var0 + '+' + var1 + '=';
    },
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("SHOW_MODAL", false);

      if (
        this.formCheck("name") &
        this.emailCheck("email")
      ) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/register.php", {
            action: "register",
            email: this.form.email.data,
            name: this.capitalizeWords(this.form.name.data)
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              //this.$store.commit("DO_LOGIN", true);
              //this.$store.dispatch("SET_USERDATA", response.data);
              //this.$router.push({ path: "/category/all" });
              //this.closeModal();
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    closeModal() {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      // this.form.pass.data = "";
      // this.form.pass.status = "";
      // this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
    },
  },
  computed: {},
  mounted() {
    this.generateCapcha();
  }
};
</script>
<style scoped>
.from-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0deg, 0%, 29%);
  padding: 1.25rem;
}
.form-footer {
  display: flex;
  justify-content: space-between;
  /* flex-end; */
}
.form-body{
  padding: 2rem 0;
}
</style>
