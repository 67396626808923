<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Create a new account</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <b-field v-if="form.visible" label="Name" :type="form.name.status" :message="form.name.message">
            <b-input type="text" v-model="form.name.data" placeholder="Your Name" required></b-input>
          </b-field>
          <b-field v-if="form.visible" label="Email" :type="form.email.status" :message="form.email.message">
            <b-input type="email" v-model="form.email.data" placeholder="Your email" required></b-input>
          </b-field>
          <!-- <b-field class="m-t-md" v-if="form.visible" :label="this.form.human.label" :type="form.human.status"
            :message="form.human.message" horizontal>
            <b-input type="text" v-model="form.human.data" placeholder="Your answer" required></b-input>
          </b-field> -->

          <b-message :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
          <div v-if="!form.visible" class="has-text-centered">
            <button class="button is-primary" type="button" @click="openLogin()">
              Sign in Now
            </button>
          </div>
        </section>
        <footer class="modal-card-foot" v-if="form.visible">
          <button class="button" type="button" @click="openLogin()">
            I have an account!
          </button>
          <button class="button is-primary" @click="onSubmit()">
            Register
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "RegisterModal",
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        name: {
          data: "",
          status: "",
          message: ""
        },
        email: {
          data: "",
          status: "",
          message: ""
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    capitalizeWords(string) {
      let arr = string.split(" ");
      return arr.map(element => {
        return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
      }).join(" ");
    },
    generateCapcha() {
      let var0 = Math.floor(Math.random() * 9) + 1;
      let var1 = Math.floor(Math.random() * 9) + 1;
      this.form.human.summ = var0 + var1;
      this.form.human.label = var0 + '+' + var1 + '=';
    },
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("SHOW_MODAL", false);

      if (
        this.formCheck("name") &
        this.emailCheck("email")
        // this.humanCheck()
      ) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/register.php", {
            action: "register",
            email: this.form.email.data,
            name: this.capitalizeWords(this.form.name.data)
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              //this.$store.commit("DO_LOGIN", true);
              //this.$store.dispatch("SET_USERDATA", response.data);
              //this.$router.push({ path: "/category/all" });
              //this.closeModal();
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email address";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    // passCheck(e) {
    //   let paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    //   console.log(!this.form[e].data.match(paswd));
    //   if (this.form[e].data === "" || !this.form[e].data.match(paswd)) {
    //     this.form[e].message =
    //       "Please enter a Password [7 to 15 characters which contain at least one numeric digit and a special character]";
    //     this.form[e].status = "is-danger";
    //     return false;
    //   } else {
    //     this.form[e].message = null;
    //     this.form[e].status = "is-success";
    //     return true;
    //   }
    // },
    passCheck() {
      //let paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
      //console.log(!this.form[e].data.match(paswd));
      if (this.form.pass.data === "") {
        this.form.pass.message =
          "Please enter a Password [min 6 characters]";
        this.form.pass.status = "is-danger";
        return false;
      } else {
        this.form.pass.message = null;
        this.form.pass.status = "is-success";
        return true;
      }
    },
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].message = "";
        this.form[e].status = "is-success";
        return true;
      }
    },
    humanCheck() {
      if (this.form.human.data == this.form.human.summ) {
        this.form.human.status = "is-success";
        this.form.human.message = "Human, eh?";
        return true;
      } else {
        this.form.human.status = "is-danger";
        this.form.human.message = "You did not pass the human test.";
        return false;
      }
    },
    closeModal() {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      // this.form.pass.data = "";
      // this.form.pass.status = "";
      // this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.$store.commit("modals/hideModal", "register");
    },
    openLogin() {
      this.$store.commit("modals/showModal", "login");
      this.closeModal();
    }
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.register;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "register");
        return false;
      }
    }
  },
  watch: {
    isActive: function () {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      this.form.human.data = null;
      this.form.human.status = "";
      this.form.human.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.form.visible = true;
      this.generateCapcha();
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
