<template>
  <div class="cat-wrapper">
    <div class="on-cover" :style="coverStyle"></div>
    <div class="cat-menu">
      <div class="cat-menu-item" @click="goTo(team.id)" v-for="(team, index) in teams" :class="{'blur' : isHovered && isHovered !== 'item'+index}">
        <div class="icon-wrapper" @mouseover = "isHovered = 'item'+index" @mouseout = "isHovered = null">
          <div class="circle-icon" :style="ballStyle(team.color)">
            <div class="circle-icon-gradient">
              <div class="icon-container"><i :class="'syntho '+team.icon"></i></div>
              <div class="cat-title">{{team.label}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CategoriesMenu",
  data() {
    return {
      isHovered: null,
      coverStyle: {
        backgroundColor: "#e30600",
        position: "absolute",
        top: "-50px",
        left: "-50px",
        width: "100%",
        height: "100%",
        zIndex: "100",
        borderRadius: "50%",
      },
      labels:{
        en:"labelEN",
        ru:"labelRU",
        ro:"labelRO"
      },
    };
  },
  methods: {
    goTo(id){
      this.$store.commit("setCurrentTeam", id);
      // if(this.isMobile){
      //   this.$store.commit("modals/showModal", "sidebarChat");
      // }
      // this.$store.commit("modals/showModal", "sidebarChat");
      this.$router.push({ path: "/chat"});
      //this.$router.push({ path: "/chat", query: { agent: id } });
      //---------------
      //this.$router.push("/category/"+slug);
      // this.didClick(col);
      // setTimeout(() => {
      //   this.$router.push("/adverts-list");
      // }, 500);
    },
    ballStyle(colors) {
      let style = {};

      //style.backgroundColor = color;
      if(this.isMobile){
//         background: rgb(93,57,135);
// background: linear-gradient(142deg, rgba(93,57,135,1) 0%, rgba(91,83,241,1) 100%);

        // style = `background-color: ${color};`;
        style = `background: linear-gradient(145deg, ${colors[0]} 0%, ${colors[1]} 100%);`;
      }else{
        style = `filter: drop-shadow(5px 7px 4px ${colors[0]}55) drop-shadow(0px 5px 2px #00000036) drop-shadow(-1px -2px 0px #ffffff);`;
        style += `background: linear-gradient(145deg, ${colors[0]} 0%, ${colors[1]} 100%);`;
      }
      return style;
    },
    isHover(index){
      this.isHovered = index;
    },
    didClick(col){
      console.log("CLICKED!");
      this.coverStyle.backgroundColor = col;
      var e = window.event;
      const x = e.clientX;
      const y = e.clientY;
      
      this.coverStyle.display = 'block';
      this.coverStyle.top = `${y-100}px`;
      this.coverStyle.left = `${x-100}px`;
      this.coverStyle.width = 0;
      this.coverStyle.height = 0;
      this.coverStyle.borderRadius = "0%";
      
      setTimeout(() => {
        this.coverStyle.top = '0';
        this.coverStyle.left = '0';
        this.coverStyle.width = '100%';
        this.coverStyle.height = '100%';
      }, 0);
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      teams: (state) => state.teams,
      isMobile: (state) => state.isMobile,
    }),
    //...mapState(["user"]),
    // ballStyle(color) {
    //   return {
    //     backgroundColor: this.color,
    //     filter: "drop-shadow(5px 5px 4px #"+color+"36)",
    //   };
    // },
  },
};
</script>
<style scoped>
.cat-wrapper{
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  margin: 3rem 0; 
}

.cat-menu{
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 40px;
}

.cat-menu-item{
  transition: all 0.4s ease-in-out;
  /* grid-area: a;
  align-self: center;
  justify-self: center; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.icon-wrapper{
  position: relative;
  padding-bottom: 150px;
}

.icon-container{
  margin-top: 15px;
  font-size: 3rem;
  transition: all 0.1s ease-out;
}

.icon-container i{
   /* filter: drop-shadow(5px 5px 4px #00000036); */
}
.circle-icon{
  left:0;
  right:0;

  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 200px;
  height: 120px;
  background-color: #000000;
  border-radius: 15px;
  /* filter: drop-shadow(5px 5px 4px #00000036); */
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: 0 0 0 8px #ffffff65;
}

.circle-icon:hover{
  width: 220px;
  height: 140px;
  transform: translate(0px, -10px);
  /* transform: translate(-10px, -10px); */
}
/* .circle-icon .icon{
  color: #ffffffec;
  font-size: 3rem;
  transition: all 0.1s ease-out;
} */
.circle-icon:hover .icon-container{
  transform: scale(1.3) translate(0px, 8px);
  filter: drop-shadow(5px 5px 4px #00000040);
}
.circle-icon-gradient{
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,0.09987745098039214) 0%, rgba(0,0,0,0) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: #ffffffec;
  box-shadow: inset 0px 0px 3px 1px #ffffff7c;
  transition: all 0.2s ease-out;
  /* text-shadow: 2px 2px #00000063; */
}

.circle-icon:hover .circle-icon-gradient{
  box-shadow: inset 0px 0px 16px 1px #00000060;
  /* text-shadow: 2px 2px #00000063; */
}

.cat-title{
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  justify-self: center;
  /* text-transform: uppercase; */
  font-size: 1.3rem;
  font-weight: 400;
}

/* .blur{
  filter: opacity(70%);
} */

.blur .icon-wrapper {
  /* filter: opacity(70%); */
  /* width: 90px;
  height: 90px;
  transform: translate(0px, 5px); */
  /* transform: translate(-10px, -10px); */
}

.on-cover{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 100;
  transition: all 0.2s ease-in-out;
  display: none;
}


.pulse {
  /* animation: pulse-animation 1s;
  animation-delay: 0.2s; */
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #ffffff;
    /* box-shadow: 0 0 0 0px #7cbc4b7e; */
  }
  100% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
}


/*mobile styles braking points*/

@media (max-width: 768px) {
  .cat-menu{
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 15px;
  }
  .cat-menu-item{
    margin: 0;
  }
  .cat-title{
    font-size: 1rem;
  }
  .icon-container{
    font-size: 2rem;
  }
  .icon-wrapper{
    padding-bottom: 110px;
  }
  .circle-icon{
    width: 95%;
    height: 90px;
  }
  .circle-icon:hover{
    width: 95%;
    height: 90px;
    transform: translate(0px, -5px);
  }
  .circle-icon-gradient{
    font-size: 0.8rem;
  }
  .circle-icon:hover .icon-container{
    transform: scale(1.3) translate(0px, 5px);
  }
}




</style>
