<template>
  <div>
    <footer class="footer">
      <div class="container has-text-centered">
        <small class="has-text-ddlight"
          >{{ currentYear }} &copy; FoxSolution | All Rights Reserved.
        </small>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState(["isLoged"]),
  },
};
</script>
