<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      custom-class="modal-contact"
      :can-cancel="false"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Contact Us</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <div v-if="form.visible">
            <b-field
              label="Name"
              :type="form.name.status"
              :message="form.name.message"
            >
              <b-input
                v-model="form.name.data"
                placeholder="Your Name"
                required
              ></b-input>
            </b-field>
            <b-field
              label="Email"
              :type="form.email.status"
              :message="form.email.message"
            >
              <b-input
                type="email"
                v-model="form.email.data"
                placeholder="Your email"
                required
              ></b-input>
            </b-field>
            <!-- <b-field
              label="Phone"
              :type="form.phone.status"
              :message="form.phone.message"
            >
              <b-input
                v-model="form.phone.data"
                placeholder="Your Phone"
                required
              ></b-input>
            </b-field> -->
            <b-field
              label="Message"
              :type="form.text.status"
              :message="form.text.message"
              expanded
            >
              <b-input
                maxlength="500"
                type="textarea"
                v-model="form.text.data"
                placeholder="Your Message"
              ></b-input>
            </b-field>
            <b-field
              label="Are you human?"
              :type="form.human.status"
              :message="form.human.message"
            >
              <b-select
                v-model="form.human.data"
                placeholder="Select Answer"
                expanded
              >
                <option value="1">No, I'm not!</option>
                <option value="2">What is a human?</option>
                <option value="3">I wish I was :)</option>
                <option value="4">Indeed, I am!</option>
                <option value="5">Maybe Someday...</option>
              </b-select>
            </b-field>
          </div>

          <div class="content responce">
            <b-message :type="form.status" v-if="form.result">
              {{ form.message }}
            </b-message>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button type="is-ligt" @click="closeModal()">
            Cancel
          </b-button>
          <b-button
            v-if="form.visible"
            type="is-primary"
            @click="sendMessage()"
          >
            Send
          </b-button>
          <b-button
            v-if="!form.visible"
            type="is-primary"
            @click="resetForm()"
          >
            Send Another
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "Contact",
  props: {
    email: String
  },
  data() {
    return {
      token: null,
      form: {
        email: {
          data: "",
          status: null,
          message: null
        },
        name: {
          data: "",
          status: null,
          message: null
        },
        phone: {
          data: "",
          status: null,
          message: null
        },
        text: {
          data: "",
          status: null,
          message: null
        },
        human: {
          data: null,
          status: null,
          message: null
        },
        visible: true,
        message: null,
        result: false,
        status: null
      }
    };
  },
  methods: {
    getToken: function() {
        this.axios
          .post("functions/token.php", {
            action: "getToken"
          })
          .then(response => {
            //this.isLoading = false;  
            console.log(response.data);        
            if (response.data.success) {
              this.token = response.data.token; 
            }
          })
          .catch(error => {
            //this.isLoading = false;
            console.log(error);
          });
      //end post-------------------------------------------------
    },
    sendMessage() {
      if (
        this.emailCheck("email") &
        this.formCheck("name") &
        // this.formCheck("phone") &
        this.formCheck("text") &
        this.humanCheck("human")
      ) {

        this.axios
          .post("contact/sendmail.php", {
            name: this.form.name.data,
            email: this.form.email.data,
            message: this.form.text.data,
            token: this.token
          })
          .then(response => {
            //this.isLoading = false;  
            //console.log(response);   
            if(response.data.success){
              this.form.visible = false;
              this.form.status = "is-success";
              this.form.result = true;
              this.form.message = response.data.message;
            }else{
              this.form.status = "is-danger";
              this.form.result = true;
              this.form.message = "There was an error sending your message. Please try again.";
            }
          })
          .catch(error => {
            //this.isLoading = false;
            console.log(error);
          });
      //end post-------------------------------------------------

        this.form.visible = false;
        this.form.status = "is-success";
        this.form.result = true;
        this.form.message = "Message Sent.";
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (!this.form[e].data || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email address";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].status = "is-success";
        return true;
      }
    },
    itemCheck(e) {
      if (!this.item[e].data) {
        this.item[e].status = "is-danger";
        this.item[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.item[e].status = "is-success";
        this.item[e].message = "";
        return true;
      }
    },
    humanCheck(e) {
      if (this.form[e].data == 4) {
        this.form[e].status = "is-success";
        this.form[e].message = "Human, eh?";
        return true;
      } else {
        this.form[e].status = "is-danger";
        this.form[e].message = "You did not pass the human test.";
        return false;
      }
    },
    closeModal() {
      this.$store.commit('modals/hideModal', 'contact');
    },
    resetForm() {
      this.form.visible = true;
      this.form.status = "is-success";
      this.form.result = false;
      this.form.message = "";
      this.form.name.data = "";
      this.form.email.data = "";
      this.form.phone.data = "";
      this.form.text.data = "";
    }
  },
  mounted(){
    console.log("contact mounted");
    this.getToken();
  },
  computed: {
    isActive: {
      get: function() {
        return this.$store.state.modals.contact;
      },
      // setter
      set: function() {
        this.$store.commit('modals/hideModal', 'contact');
        return false;
      }
    }
  }
};
</script>
<style scoped>
  .modal-card-foot {
    justify-content: space-between; /* flex-end; */
  }
  .modal-card-body {
    max-height: calc(100svh - 100px) !important;
  }
  .responce{
    color: #181818!important;
  }
  
</style>
