<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
    >
      <div
        class="modal-card"
        style="width: auto"
        @keyup.enter.exact="onSubmit()"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Sign Out</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">

          Are you sure you want to Sign Out? <br/>
          
          <b-field class="mt-5">                                      
            <b-checkbox v-model="keep">Backup my personal grids before logout.</b-checkbox>
          </b-field>


        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Cancel
          </button>
          <button class="button is-primary" @click="doLogout()" :loading="isLoading">Sign Out</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
export default {
  name: "LogoutModal",
  mixins:[globalMixin],
  data() {
    return {
      keep: true,
      isLoading: false,
    };
  },
  methods: {
    doLogout() {
      if(!this.keep){
        console.log("No Backups.");
        this.$store.dispatch("users/logoutUser");

      }else{
        this.$store.dispatch("layouts/backupPersonalLayouts");
        this.isLoading = true;
        setTimeout(() => {
        this.isLoading = false;
        this.$store.dispatch("users/logoutUser");
      }, 5000);
      }
      //this.$store.commit("users/logoutUser");
      //this.$store.dispatch("users/logoutUser");
      this.showLogin();
      this.closeModal();
    },
    closeModal() {
      this.$store.commit("modals/hideModal", "logout");
    },
  },
  computed: {
    isActive: {
      get: function() {
        return this.$store.state.modals.logout;
      },
      // setter
      set: function() {
        this.$store.commit("modals/hideModal", "logout");
        return false;
      }
    }
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
</style>
