export default{
  namespaced: true,
  state: {
    //navigation modals
      sidebarChat: true,

    //general modals
      prompts: false,

    //account modals  
      login: false,
      logout: false,
      register: false,
      restore: false,
      password: false,//?
      confirm: false,//?
      pay: false,//?
      addPayment: false,//?
      contact: false,
      changelog: false,
      account: false,
      billing: false,
  },
  getters: {
  },
  mutations: {
    showModal(state, payload) {
      state[payload] = true;
    },
    hideModal(state, payload) {
      state[payload] = false;
    },
    toggleModal(state, payload) {
      state[payload] = !state[payload];
    },
  },
  actions: {
  },
  modules: {
  }
}
