<template>
  <div id="app">
    <Navbar v-if="['Chat'].indexOf($route.name) == -1"/>
    <div :class="['Chat'].indexOf($route.name) == -1 ? 'main':''">
      <router-view />
    </div>
    <Footer v-if="['Chat'].indexOf($route.name) == -1" />
    <Contact/>
    <!-- <Log/> -->
    <LoginModal/>
    <LogoutModal/>
    <RegisterModal/>
    <RestoreModal/>    
  </div>
</template>


<script>
import Navbar from "@/components/navigation/Navbar.vue";
import Footer from "@/components/navigation/Footer.vue";

import Contact from '@/components/Contact.vue';
// import Log from '@/components/Log.vue';
import LoginModal from '@/components/modals/LoginModal.vue';
import LogoutModal from '@/components/modals/LogoutModal.vue';
import RegisterModal from "@/components/modals/RegisterModal.vue";
import RestoreModal from "@/components/modals/RestoreModal.vue";

import toastMixin from "@/mixins/toastMixin";
import detectMobile from "@/mixins/detectMobile";

export default {
  name: "app",
  components: {
    Navbar,
    Footer,
    Contact,
    // Log,
    LoginModal,
    LogoutModal,
    RegisterModal,
    RestoreModal,
  },
  data() {
    return{
      isMobile: false,
    };
  },
  mixins: [toastMixin, detectMobile],

  beforeMount() {
    this.$store.dispatch("getTeams");
    this.isMobile = this.detectMobile();
    this.$store.commit("setIsMobile", this.isMobile);
  },
  mounted() {
    console.log("APP MOUNTED!");
    if(localStorage.aiLim){
      this.$store.commit("setMessageLimit", localStorage.aiLim);
    }else{
      this.$store.commit("setMessageLimit", 100);
    }
    //=================================================================
    //mobile chat fix
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    //end mobile chat fix
    //=================================================================
    // //this.$store.dispatch("loadCategories");

    // if(localStorage.sxLang){
    //   this.$store.commit("setLang", localStorage.sxLang);
    //   this.$i18n.locale = localStorage.sxLang;
    // }
    // //!! this.$store.dispatch("loadCategories");
    console.log("Begin User Check!");
    if (!this.$store.state.users.user.isLogged) {
      this.checkLogged();
      console.log(this.$store.state.users.user.isLogged);
      //console.log(this.$route.name);
    }

  },
  methods: {
    checkLogged: function () {
      if (localStorage.aiToken) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.aiToken);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/users/validate.php`, {
            token: localStorage.aiToken,
          })
          .then((response) => {
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("users/validateUser", response.data.data);
              console.log("Loged!");
              console.log(response.data.data);
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
  },
  beforeDestroy() {
  },
  created() {
    //this.countInbox();
   // this.pollData();
  },
};
</script>


<style lang="scss">
// @supports (viewport-fit: cover) {
//   html, body {
//     height: 100vh;
//     viewport-fit: cover;
//   }
// }
// html, body {
//   height: 100vh;
// }

#app{
  background-color: #f5f5f5;
  // height: 100svh;
  // overflow: hidden;
  // min-height: 100vh;
}

//Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap");
// Import Fontawesome
//@import "static/fontawesome/css/all.min.css";
@import "static/syntho/syntho-style.css";
@import "static/salex/style.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
//$primary: #97a3ae;
$success: #7cbc4b;
$primary: #00C793;
// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #23262b; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2
// $dark: #0d4553;
$filter: #f4f4f4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
// $body-background-color: #4a4f54;
$content-heading-color: #000000;

$btportable: #ffcd0b;
$btmodular: #f37422;
$btcustom: #ed3a25;
$btgraphic: #71cee3;
$btproject: #b393b0;

$bt-violet: #6E4FD7;
$bt-pink: #FF008D;
$bt-orange: #FFB000;
$bt-bluegreen: #00C793;

$btgreen: #7cbc4b;
$btgreen-light: #8dc457;
$btgreen-dark: #669f42;
$btgreen-dark2: #5f9340;

$btgray: #4a4f54;
$btgray2: #41464c;
$btgray3: #383c41;
$btgray4: #2e3136;
$btgray5: #23262b;

$btlight: #97a3ae;
$btlight2: #d8dfe1;
$btlight3: #f7f7f7;

// $navbar-item-color: $btgray!important;
// $navbar-dropdown-arrow: $btgray!important;

$footer-background-color: $btgray5;
$footer-color: $btlight2;
$footer-padding: 2rem 1.5rem 2rem;

//$family-primary: 'Roboto', sans-serif;
$family-primary: "Roboto", sans-serif;

$card-radius: 0.5rem;
// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "mydark": (
    $mydark,
    $white,
  ),
  "mylight": (
    $mylight,
    $black,
  ),
  "filter": (
    $filter,
    $white,
  ),
  "btportable": (
    $btportable,
    $white,
  ),
  "btmodular": (
    $btmodular,
    $white,
  ),
  "btcustom": (
    $btcustom,
    $white,
  ),
  "btgraphic": (
    $btgraphic,
    $white,
  ),
  "btproject": (
    $btproject,
    $white,
  ),
  "btgreen": (
    $btgreen,
    $white,
  ),
  "btgreen-light": (
    $btgreen-light,
    $white,
  ),
  "btgreen-dark": (
    $btgreen-dark,
    $white,
  ),
  "btgreen-dark2": (
    $btgreen-dark2,
    $white,
  ),
  "btgray": (
    $btgray,
    $white,
  ),
  "btgray2": (
    $btgray2,
    $white,
  ),
  "btgray3": (
    $btgray3,
    $white,
  ),
  "btgray4": (
    $btgray4,
    $white,
  ),
  "btgray5": (
    $btgray5,
    $white,
  ),
  "btlight": (
    $btlight,
    $white,
  ),
  "btlight2": (
    $btlight2,
    $btgray,
  ),
  "btpink": (
    $bt-pink,
    $white,
  ),
  "btviolet": (
    $bt-violet,
    $white,
  ),
  "btorange": (
    $bt-orange,
    $black,
  ),
  "btbluegreen": (
    $bt-bluegreen,
    $white,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$input-icon-color: $btlight;
$input-icon-active-color: $btgray;


.main {
  padding-top: 40px;
  min-height: calc(100vh - 135px);
}

.home {
  padding-top: 40px;
}

.navbar-item img {
    max-height: 2.5rem!important;
}

ol{
  padding-left: 2rem!important;
}


@media (max-width: 768px) {
  .main {
    padding-top: 0px;
  }
  .step{
    padding: 10px 10px !important;
  }
}


.chart-container {
  height: 100vh;
  height: 100svh;
  padding-bottom:110px;
}

.icon.is-large {
  font-size: 3rem;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
// @import "~bulma-prefers-dark/bulma-prefers-dark.sass"; ! this is a potential solutionn for dark mode
//Uses @media (prefers-color-scheme: dark) to detect dark mode
</style>
