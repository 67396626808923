<template>


<div class="from-wrapper">

          <div class="sx-block-title">User Login</div>
          <div class="form-body">
            <b-field horizontal label="Email" :type="form.email.status" :message="form.email.message">
              <b-input type="email" v-model="form.email.data" placeholder="Your email" required></b-input>
            </b-field>

            <b-field horizontal label="Password" :type="form.pass.status" :message="form.pass.message">
              <b-input type="password" v-model="form.pass.data" password-reveal placeholder="Your password" required>
              </b-input>
            </b-field>


            <b-message :type="form.status" v-if="form.result">
              {{ form.message }}
            </b-message>
            <!-- <div v-if="!form.visible" class="has-text-centered">
              <button class="button is-primary" type="button" @click="openLogin()">
                Sign in Now
              </button>
            </div> -->

        </div>
        <div class="form-footer" v-if="form.visible">
          <button class="button" type="button" @click="openRestore()">
            Forgot password?
          </button>
          <button class="button is-primary" @click="onSubmit()">Log In</button>
        </div>
      </div>

</template>

<script>
import formCheck from "@/mixins/formCheck";

export default {
  name: "LoginForm",
  mixins: [formCheck],
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        email: {
          data: "",
          status: "",
          message: ""
        },
        pass: {
          data: "",
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("modals/showModal", false);

      if (this.emailCheck("email") & this.passCheck("pass")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/login.php", {
            email: this.form.email.data,
            pass: this.form.pass.data
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
             // this.$store.commit("users/loginUser", response.data);
              this.$store.dispatch("users/loginUser", response.data);
              // if(response.data.status==0){
              //   this.doConfirm();
              // }
              //this.$router.push({ path: "/category/all" });
             } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else if (response.data.type == "pass") {
                this.form.pass.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    closeModal() {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      this.form.pass.data = "";
      this.form.pass.status = "";
      this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
    },
    openRestore() {
      this.$store.commit("modals/showModal", "restore");
    }
  },
  computed: {},
  mounted() {
    if (localStorage.sxEmail) {
      this.form.email.data = localStorage.xsEmail;
    }
  }
};
</script>
<style scoped>
.from-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0deg, 0%, 29%);
  padding: 1.25rem;
}
.form-footer {
  display: flex;
  justify-content: space-between;
  /* flex-end; */
}
.form-body{
  padding: 2rem 0;
}
</style>
